"use client";

import { ROUTES } from "@/constants/routes";
import CloseIcon from "@/icons/close.svg";
import LogoSvg from "@/logo.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";

export function BookingHeader() {
  const pathname = usePathname();

  return (
    <header className="booking-header">
      <div className="header-content">
        <Link className="logo-link" href={ROUTES.home} title="Home">
          <LogoSvg />
        </Link>

        {pathname === ROUTES.booking && (
          <Link href={ROUTES.home} className="close-link btn btn-outline btn-primary btn-sm">
            <CloseIcon />
            Close
          </Link>
        )}
      </div>
    </header>
  );
}
